<template>
  <div class="login">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">注册</div>
    <img class="bg" src="../../assets/img/loginbg.png" alt="">
    <div class="login-card">
        <div class="catalog">
            <p>用户名<span class="key">*</span></p>
            <input type="text" placeholder="6-20个字符" v-model="userName">
        </div>
        <div class="catalog">
            <p>密码<span class="key">*</span></p>
            <input type="password" placeholder="12-20个字符，包含字母和数字" v-model="password">
        </div>
        <div class="catalog">
            <p>确认密码<span class="key">*</span></p>
            <input type="password" placeholder="请再次输入密码" v-model="passwordAgain">
        </div>
        <div class="catalog">
            <p>企业名称<span class="key">*</span></p>
            <input type="text" placeholder="贵企业名称" v-model="enterName">
        </div>
        <div class="catalog">
            <p>所属市<span class="key">*</span></p>
            <div class="select">
            <select v-model="cityCode">
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>区<span class="key">*</span></p>
            <div class="select">
            <select v-model="districtCode">
                <option v-for="item in district" :key="item.cityCode" :value="item.cityCode">{{item.cityName}}</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>企业性质<span class="key">*</span></p>
            <div class="select">
                <select v-model="enterProperty">
                    <option value="0">企业法人</option>
                    <option value="1">企业非法人</option>
                    <option value="2">事业单位</option>
                    <option value="3">个体户</option>
                    <option value="4">其他</option>
                </select>
            </div>           
        </div>
        <div class="catalog">
            <p>统一社会信用代码<span class="key">*</span></p>
            <input type="text" placeholder="务必真实准确填写" v-model="uniscid">
        </div>
        <div class="catalog">
            <p>法人姓名<span class="key">*</span></p>
            <input type="text" v-model="legalName">
        </div>
        <div class="catalog">
            <p>法人身份证号<span class="key">*</span></p>
            <input type="text" v-model="legalCertiNum">
        </div>
        <div class="catalog">
            <p>联系人姓名<span class="key">*</span></p>
            <input type="text" v-model="contactsName">
        </div>
         <div class="catalog">
            <p>手机号码<span class="key">*</span></p>
            <input type="number" placeholder="有效的11位手机号码" v-model="contactsPhone">
        </div>
        <div v-show="mobPhoneVeriCodeFlag === '1'" class="catalog">
            <p>手机验证码<span class="key">*</span></p>
            <input type="number" placeholder="验证码" style="width: 29vw;margin:0 1vw 0 3vw;" v-model="verificationCode">
            <div v-show="showVerifyBtn===0" class="verify" @click="sendVerify()">发送验证码</div>
            <div v-show="showVerifyBtn===1" class="verify2">
                <van-count-down
                ref="countDown"
                :time="60000"
                format="ss"
                @finish="countDownFinish"
                />秒
             </div>
        </div> 
        <div class="catalog">
            <p>电子邮箱</p>
            <input type="text" placeholder="联系人的电子邮箱" v-model="email">
        </div>
        <div class="catalog" style="align-items: flex-start;">
            <p>引导机构</p>
            <div class="many">
            <div class="select">
            <select v-model="guideCity">
                <option value="">请选择</option>
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
            </select>
            </div>
            <div class="select">
            <select v-model="guideBranch">
                <option value="">请选择</option>
                <option v-for="item in guideBranchList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
            </select>
            </div>
            <div class="select">
            <select v-model="guideAgency">
                <option value="">请选择</option>
                <option v-for="item in guideAgencyList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
            </select>
            </div>
            </div>
        </div>
         <div class="catalog">
            <p>引导人</p>
            <input type="text" v-model="guidePerson">
        </div>   
       

        <button class="login-btn" @click="submit()">注册</button>
        <div class="login-footer flex-between">
            <router-link to="/login" replace class="blue">马上登录</router-link>
            <router-link to="/passwordForget" replace class="gray">忘记密码</router-link>
        </div>

    </div>
    
  </div>
</template>
<script>
import { Toast } from 'vant';
import { reactive,ref,toRefs,toRaw,watch } from'vue';
import { useRouter } from 'vue-router';
import { apiGoRegister,apiSendVerificationCode,apiSaveRegisterInfo,apiGetDistrict,apiBranchBanks,apiSubBranchBanks } from '../../api/axios';
export default {
   setup() {
       let router = useRouter();
       const countDown = ref(null);
       const state = reactive({
           passwordAgain: '',
           showVerifyBtn: 0,
           mobPhoneVeriCodeFlag: '1',
           district:[{cityCode: '450101',cityName: '市辖区'}],
           guideCity: '',
           guideBranch: '',
           guideBranchList: [],
           guideAgencyList: []
       });
       const registerInfo = reactive({
           userName:'',
           password:'',
           enterName: '',
           cityCode:'450100',
           districtCode: '450101',
           enterProperty: '0',
           uniscid: '',
           legalName: '',
           legalCertiNum: '',
           contactsName: '',
           contactsPhone: '',
           verificationCode: '',
           email: '',
           guideAgency: '',
           guidePerson: '',
       })
        //进入注册页面
       apiGoRegister().then(res => {
            // 获取数据成功后的其他操作
           console.log(res,'获取的数据')
           if(res.code === '1'){
                state.mobPhoneVeriCodeFlag = res.info.mobPhoneVeriCodeFlag;       
            }
            else {
                Toast(res.msg);
            }           
        }).catch(err=>{
            console.log(err,'请求错误')
        });
        //获取区县
        function getDistrict() {
            apiGetDistrict({cityCode: registerInfo.cityCode}).then(res => {
                // 获取数据成功后的其他操作
            console.log(res,'获取的数据')
            if(res.code === '1'){
                    state.district = res.info.districtList;
                    registerInfo.districtCode = state.district[0].cityCode;      
                }
                else {
                    Toast(res.msg);
                }           
            }).catch(err=>{
                console.log(err,'请求错误')
            }) 
        };
        getDistrict();
        //监听所属市变化
        watch(()=> [registerInfo.cityCode,state.guideCity,state.guideBranch], ([new1,new2,new3],[old1,old2,old3] ) => {
            if(new1 != old1) {
                getDistrict();
            }
            if(new2!=old2) {
                if(new2 != '') {                    
                    apiBranchBanks({cityCode: new2}).then(res => {                
                        if(res.code === '1'){
                            state.guideBranchList = res.info.bankList;
                            state.guideAgencyList.splice(0,state.guideAgencyList.length);
                            registerInfo.guideAgency = '';
                            state.guideBranch = '';                            
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideBranchList.splice(0,state.guideBranchList.length);
                    state.guideBranch = '';
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    registerInfo.guideAgency = '';
                }
                
            }
            if(new3 != old3) {
                if(new3 != '') {
                    apiSubBranchBanks({branchBankCode: new3}).then(res => {
                        if(res.code === '1'){
                            state.guideAgencyList = res.info.subBankList;
                            registerInfo.guideAgency = '';  
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    registerInfo.guideAgency = '';  
                }
            }

        })

        //发送验证码
       const sendVerify = () => {
           let phoneNum = registerInfo.contactsPhone.toString();  
           if(phoneNum.length != 11) {
            //    console.log(phoneNum)
               Toast('手机号格式错误');
               return;
           }else {
                apiSendVerificationCode({contactsPhone: phoneNum}).then(res => {
                // 获取数据成功后的其他操作
                console.log(res,'获取的数据')
                if(res.code === '0'){
                        Toast(res.msg);
                    }           
                }).catch(err=>{
                    console.log(err,'请求错误')
                }) 
                state.showVerifyBtn = 1;
                countDown.value.reset();//重置倒计时
           }

          
       };
       //倒计时完成
       const countDownFinish = () => {
           state.showVerifyBtn = 0;           
       };
       //提交注册
       const submit = () =>{
           if(state.passwordAgain != registerInfo.password) {
               Toast('两次密码输入不一致！')
               return;
           }
           registerInfo.contactsPhone = registerInfo.contactsPhone.toString();
           console.log(toRaw(registerInfo));
           apiSaveRegisterInfo(toRaw(registerInfo)).then(res => {
            if(res.code === '1'){
                    Toast.success({
                        forbidClick: true,
                        message: '注册成功',
                    });
                    router.push({ name:'login',replace:true}); 
                }
                else {
                    Toast.fail(res.msg);
                }           
            }).catch(err=>{
                console.log(err,'请求错误')
            }) 
       }
       return {
           countDown,
           ...toRefs(state),
           ...toRefs(registerInfo),
           sendVerify,
           countDownFinish,
           submit
           
       }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.login {
    background-color: #fff;
    .navTitle {
        background: none;
    }
    img.bg {
        width: 100%;
        height: auto;
    }
    .login-card {
        margin: 0 8vw;
        padding-bottom: 15vw;
        .catalog {
            display: flex;
            align-items: center;
            
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;

            }
            select,input {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            .many {
                width: 59vw;
                .select {
                    margin-bottom: 4vw;
                }
            }
            .verify {
                color: #ff9700;
                padding: 1vw 2vw;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
            }
            .verify2 {
                padding: 1vw 0;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
                // color: #999;
                color: #ff9700;
                width: 23vw;
                display: flex;
                justify-content: center;
            }

        }
        .login-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 3vw 6vw;
            border-radius: 7vw;
            border: 0;
            margin-top: 8vw;
            width: 100%;
            letter-spacing: 2vw;
        }   
    
    }
    .login-footer {
        margin-top: 4vw;        
        font-size: 4vw;
        .blue {
            color: #3399ff
        }
        .gray {
            color: #666666;
        }
    }
    
}
</style>
